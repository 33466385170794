import { get } from "lodash"
import { ButtonModel } from "./Button.model"
import { NavigationModel } from "./Navigation.model"

export class FooterModel {
  constructor(data) {
    this.data = data
  }

  get backgroundImage() {
    return get(this.data, "elements.background_image.value[0].url", "")
  }

  get button() {
    const buttonData = get(this.data, "elements.button.linked_items[0]", {})

    const { color, image, name, size, slug } = new ButtonModel(buttonData)

    return {
      color,
      image,
      name,
      size,
      slug,
    }
  }

  get navigation() {
    const navigationData = get(
      this.data,
      "elements.navigation.linked_items",
      []
    )

    const { navLinks } = new NavigationModel(navigationData)

    return navLinks
  }

  get leftSideImage() {
    return get(this.data, "elements.left_side_image.value[0].url", "")
  }

  get rightSideImage() {
    return get(this.data, "elements.right_side_image.value[0].url", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get subtitle() {
    return get(this.data, "elements.subtitle.value", "")
  }
}
