import React from "react"
import PropTypes from "prop-types"

import { FooterModel } from "../../models/Footer.model"

import Button from "../../components/blocks/Button/Button"
import LocalizedLink from '../../components/blocks/LocalizedLink/LocalizedLink'

import styles from "./FooterView.module.scss"

const FooterView = ({ footerData }) => {
  if (!footerData) return null

  const {
    backgroundImage,
    button,
    leftSideImage,
    rightSideImage,
    navigation,
    title,
    subtitle,
  } = new FooterModel(footerData)

  return (
    <footer className={styles.Footer}>
      <div
        className={styles.Container}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={styles.ContentContainer}>
          <h3 className={styles.Title}>{title}</h3>
          {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
          <Button className={styles.FooterButton} {...button} />
        </div>
        <img src={leftSideImage} className={styles.LeftImage} alt="" />
        <img src={rightSideImage} className={styles.RightImage} alt="" />
        <nav className={styles.Navigation}>
          <ul className={styles.NavigaitonList}>
            {navigation.map(navItem => (
              <li key={navItem.text}>
                {navItem.isExternalLink ? (
                  <a
                    href={navItem.slug}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {navItem.text}
                  </a>
                ) : (
                  <LocalizedLink to={`${navItem.slug}`}>{navItem.text}</LocalizedLink>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default FooterView

FooterView.propTypes = {
  footerData: PropTypes.object.isRequired,
}
