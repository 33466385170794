import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { get } from "lodash"

import FooterView from "../views/FooterView/FooterView"
import Nav from "../components/containers/Nav/Nav"
import PageContentParser from "../components/containers/PageContentParser/PageContentParser"

import { useSiteLanguages } from '../hooks/useSiteLanguages'
import { LANGUAGES } from "../utils/consts"
import getDataFromURL from "../utils/inbound"

const Page = props => {
  const elements = get(props, "data.allKontentItemPage.nodes[0].elements", {})

  const buttonData = get(elements, "cta_button.linked_items[0]", [])
  const contentData = get(elements, "content.linked_items", [])
  const logo = get(elements, "logo.value[0].url", "")
  const navigationData = get(elements, "navigation_bar.linked_items", [])
  const metaTitle = get(elements, "seo__meta_title.value", "")
  const metaImage = get(elements, "seo__meta_image.value[0].url", "")
  const metaDescription = get(elements, "seo__meta_description.value", "")
  const ogType = get(elements, "seo__og_type.value[0].name", "")

  React.useEffect(() => {
    getDataFromURL();
  }, [])

  const { redirectOnLangChange, getLangFromPathname } = useSiteLanguages()
  const languages = Object.values(LANGUAGES).map(lang => lang.toLowerCase())
  if (typeof window !== 'undefined') {
    var userLang = localStorage.getItem('language') || navigator.language.slice(0, 2);
    userLang = languages.includes(userLang) ? userLang : 'en'
    localStorage.setItem('language', userLang);
    if (userLang !== getLangFromPathname()) redirectOnLangChange(userLang)
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:type" content={ogType || "website"} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        {typeof window !== 'undefined' && (<link rel='canonical' href={window.location.href} />)}
      </Helmet>
      <Nav
        navigationData={navigationData}
        logo={logo}
        buttonData={buttonData}
      />
      <PageContentParser contentData={contentData} />
      <FooterView footerData={get(elements, "footer.linked_items[0]", [])} />
    </>
  )
}

export default Page

export const query = graphql`
  query($pageId: String, $language: String) {
    allKontentItemPage(
      filter: { preferred_language: { eq: $language }, id: { eq: $pageId } }
    ) {
      nodes {
        id
        elements {
          logo {
            value {
              url
            }
          }
          cta_button {
            linked_items {
              ...UiButtonFragment
            }
          }
          content {
            linked_items {
              ...AccordionSectionFragment
              ...ContactSectionFragment
              ...CustomersReferencesFragment
              ...CustomersFragment
              ...DownloadSectionFragment
              ...HeaderFragment
              ...HowItWorksFragment
              ...MethodologyFragment
              ...NumbersSectionFragment
              ...NavigatorFragment
              ...OneColumnFragment
              ...OneColumnWithSliderFragment
              ...OurTeamFragment
              ...RichTextFragment
              ...TwoColumnsHeaderFragment
              ...TwoColumnsListFragment
              ...TwoColumnsWithSliderFragment
              ...TwoColumnsFragment
              ...TwoColumnsWithTitleFragment
              ...ThreeFourColumnsSectionFragment
            }
          }
          navigation_bar {
            linked_items {
              ...NavigationComponentFragment
            }
          }
          footer {
            linked_items {
              ...FooterFragment
            }
          }
          seo__meta_description {
            value
          }
          seo__meta_image {
            value {
              url
            }
          }
          seo__meta_title {
            value
          }
          seo__og_type {
            value {
              name
            }
          }
        }
      }
    }
  }
`
