import React, { useContext, useState } from "react"

import { useIsMobile } from "../../../hooks/useIsMobile"
import { useWindowScroll } from "../../../hooks/useWindowScroll"

import { LangContext } from "../../../context/LangContext"

import { ButtonModel } from "../../../models/Button.model"
import { NavigationModel } from "../../../models/Navigation.model"

import { useSiteLanguages } from "../../../hooks/useSiteLanguages"

import Button from "../../blocks/Button/Button"
import LangMenu from "../LangMenu/LangMenu"
import LocalizedLink from "../../blocks/LocalizedLink/LocalizedLink"

import CloseBtn from "../../../images/close_burger.svg"
import HamburgerIcon from "../../../images/burger_menu.svg"

import styles from "./Nav.module.scss"

const Nav = ({ buttonData, logo, navigationData }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false)
  const { languages, pageLang } = useContext(LangContext)
  const isScrolledFromTop = useWindowScroll()
  const { isTablet } = useIsMobile()

  const { navLinks } = new NavigationModel(navigationData)
  const { color, image, name, size, slug } = new ButtonModel(buttonData)
  const button = { color, image, name, size, slug }
  const { redirectOnLangChange } = useSiteLanguages()

  const openClass = isOpen ? styles.Open : ""
  const hiddenLogoClass = isScrolledFromTop ? styles.Hidden : ""

  const toggle = () => {
    setIsOpen(!isOpen)
    document.body.classList.toggle("menu-open")
  }

  const toggleLangMenu = () => setIsLangMenuOpen(!isLangMenuOpen)

  return (
    <header className={styles.HeaderContainer}>
      <LocalizedLink to={''} className={`${styles.LogoLink}`}>
        <img
          src={logo}
          alt="Grow Uperion logo"
          className={`${styles.Logo} ${hiddenLogoClass}`}
        />
      </LocalizedLink>
      {!isTablet && (
        <>
          <nav className={styles.NavigationWrapper}>
            <ul className={styles.Nav}>
              {navLinks.map(navLink => (
                <li key={navLink.text} className={styles.ListItem}>
                  <LocalizedLink to={navLink.slug} className={styles.Link}>
                    {navLink.text}
                  </LocalizedLink>
                </li>
              ))}
            </ul>
            <div className={styles.LangWrapper}>
              <button className={styles.LangButton} onClick={toggleLangMenu}>
                {pageLang}
              </button>
              <LangMenu isOpen={isLangMenuOpen} />
            </div> 
           
          </nav>
          <Button {...button} className={styles.NavButton} />
        </>
      )}
      {isTablet && (
        <>
          <div onClick={toggle} className={styles.HamburgerIconContainer}>
            {!isOpen ? (
              <img
                className={styles.HamburgerIcon}
                src={HamburgerIcon}
                alt="hamburger-icon"
              />
            ) : null}
          </div>
          <nav className={`${styles.MobileNavigationWrapper} ${openClass}`}>
            {isOpen ? (
              <img
                onClick={toggle}
                className={styles.MobileCloseIcon}
                src={CloseBtn}
                alt="hamburger-icon"
              />
            ) : null}
            <>
              <ul>
                {navLinks.map(navLink => (
                  <li key={navLink.text} className={styles.ListItemMobile}>
                    <LocalizedLink
                      activeClassName={styles.LinkActive}
                      className={styles.Link}
                      to={navLink.slug}
                    >
                      {navLink.text}
                    </LocalizedLink>
                  </li>
                ))}
              </ul>
              <ul className={styles.LanguagesWrapper}>
                {languages.map(lang => (
                  <li
                    key={lang}
                    className={`${styles.LanguageButton} ${
                      lang.toLowerCase() === pageLang
                        ? styles.LanguageButtonActive
                        : ""
                    }`}
                    onClick={() => redirectOnLangChange(lang)}
                  >
                    {lang}
                  </li>
                ))}
              </ul> 
             
            </>
          </nav>
        </>
      )}
    </header>
  )
}
export default Nav
