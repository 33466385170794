import { get } from 'lodash';

export class NavigationModel {
  constructor(data) {
    this.data = data;
  }

  get navLinks() {
    const navLinks = this.data.map(item => ({
      isExternalLink: get(item, "elements.is_external_link.value[0].name", ""),
      slug: get(item, "elements.navigation_slug.value", ""),
      text: get(item, "elements.navigation_text.value", ""),
    }))

    return navLinks
  }
}